@use '../colors.scss';

.wrapper {
    cursor: pointer;
}

.selected {
    color: colors.$text-light;
    background-color: colors.$main;  
}

.unselected {
    color: colors.$main-dark;
    background-color: colors.$text-light;
    border-color: colors.$main-dark;
}