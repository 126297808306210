@use './colors.scss';

.finePrint {
  font-size: small;
  text-align: center;
  font-style: italic;
}

.smallSelect {
  font-size: small;
  padding: 10px;
  border-radius: 10px;
}

.smallButton {
  font-size: small;
  margin: 0;
  padding: 8px;
  border-radius: 8px;
}

a {
  display: inline-block;
  color: colors.$main-dark;
  text-decoration: underline dashed 1px colors.$main-dark;
  text-underline-offset: 4px;
  border-radius: 4px;
  padding: 2px;

  &:hover {
    color: colors.$main;
    text-decoration-color: colors.$main;
  }

  &:focus {
    outline: dashed colors.$accent;
    color: colors.$main;
    text-decoration: none;
    transition: 0.2s linear;
  }
}

select {
    border: 1px solid colors.$lightgray;
    outline: none;
    padding: 15px;
    font-size: medium;
    border-radius: 15px;  
  }
  
  select:focus {
    box-shadow: 0 0 5px colors.$accent;
    border: 1px solid colors.$accent;
    transition: 0.2s linear;
  }
  
  input {
    border: 1px solid colors.$lightgray;
    outline: none;
    padding: 16px;
    font-size: medium;
    border-radius: 16px;
  }
  
  input[type=button]:enabled {
    border: 1px solid colors.$text-light;
    background-color: colors.$main;
    color: black;
    cursor: pointer;
  }
  
  input[type=button]:disabled {
    background-color: colors.$disabled;
    color: colors.$disabled-text;
  }
  
  input:focus {
    box-shadow: 0 0 5px colors.$accent;
    border: 1px solid colors.$accent;
    transition: 0.2s linear;
  }
  
  input[type=button]:focus {
    border: 1px solid colors.$accent;
    transition: 0.2s linear;
  }
  
  .numberTag {
    background-color: adjust-color($color: colors.$text-light, $alpha: -0.5);
    color: colors.$dark;
    padding: 4px;
    border-radius: 4px;
    margin-left: 4px;
    font-size: small;
  }
  
  
  