.thumbUpDownTotals {
  display: flex;
  margin-left: auto;
  --upMargin: 0px;
  --downMargin: 0px;

  &.centered {
    margin-left: 0;
  }

  > span {
    display: flex;
    text-wrap: nowrap;
  }

  .upIcon {
    content:url('../../../public/icons/thumb-up.svg');
    margin: 0px 5px 0px 5px;
    height: 20px;
  }
  
  // .upIcon + .upIcon {
  //   position: relative;
  //   margin-left: var(--upMargin);
  //   border-radius: 15px;
  //   box-shadow: 0px 0px 3px #4abdac;
  // }
  
  .downIcon {
    content:url('../../../public/icons/thumb-down.svg');
    margin: 0px 5px 0px 5px;
    height: 20px;
  }
  
  // .downIcon + .downIcon {
  //   position: relative;
  //   margin-left: var(--downMargin);
  //   border-radius: 15px;
  //   box-shadow: 0px 0px 3px #f78733;
  // }
}