@use '../common/colors.scss';
@use '../common/fonts.scss';

.card {
    // max-width: 500px;
    // min-width: 310px;
    flex-grow: 1;
    border-radius: 32px;
    padding: 32px;
    margin: 0 12px 24px 12px;
    background-color: colors.$text-light;
    box-shadow: 4px 4px 24px colors.$shadow;

    .cardTitle {
        @include fonts.header;
        color: colors.$dark-header;
        margin-bottom: 8px;
    }
}