@use '../common/colors.scss';

.scrollDiv {
    display: flex;
    align-items: center;
    max-height: 400px;
    max-width: 400px;
    overflow: auto;

    input {
        margin: 4px;
        flex-shrink: 1;

        &[type=text] {
            min-width: 100px;
        }
    }

    img {
        padding: 8px;
        margin: 4px;
        height: 16px;
        cursor: pointer;
        border-radius: 8px;
        //filter: invert(71%) sepia(27%) saturate(801%) hue-rotate(122deg) brightness(89%) contrast(84%);

        &:focus {
            box-shadow: 0 0 5px colors.$accent;
            outline: 0px;
        }
    }
}