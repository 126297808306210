@use '../common/colors.scss';
@use '../common/card.scss';

.card {
  @extend .card;
  max-width: 500px;

  .cardTitle {
    @extend .cardTitle;

    span {
      color: colors.$main;
    }
  }

  .questionList {
    animation: appear 0.2s linear;
  
    ul {
      padding-left: 16px;
      margin-bottom: 0;
      line-height: 32px;
  
      a {
        font-size: large;
      }
    }
  }
}

@keyframes appear {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}