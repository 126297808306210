@use '../common/colors.scss';
@use '../common/button.scss';

.sortable {
  &:focus {
    outline: none;
  }

  .optionWrapper {
    display: flex;

    .option {
      background: colors.$text-light;
      border: 0;
      box-shadow: 0 0 8px colors.$shadow;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 4px 0;
      font-size: large;
      border-radius: 16px;
      flex-grow: 1;
      touch-action: none;
      user-select: none;

      &.activeOption {
        color: transparent;
        background-color: adjust-color($color: colors.$main, $alpha: -0.5);
        opacity: 50%;
        box-shadow: 0 0 0px;
      }

      &:not(.activeOption) {
        .dragIcon {
          padding-left: 16px;
          mask:url('../../../public/icons/drag.svg');
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;
          background-color: colors.$gray;
          margin: 0px 8px 0px 8px;
          height: 16px;
          width: 8px;
          cursor: grab;
        }
      }

      >span {
        margin: 16px;
        text-align: start;
      }

      .dragIcon {
        &.disableDrag {
          visibility: hidden;
        }
      }

      .arrows {
        margin: 0px 16px 0px auto;
        font-size: medium;
        display: flex;
        flex-direction: column;

        .button {
          @extend .button;
          
          &:enabled {
            color: colors.$main;
            font-size: large;
            width: 24px;
            height: 24px;
            border: 0;
            border-radius: 8px;
            background-color: transparent;
            padding: 0;
          }

          &:disabled {
            color: colors.$disabled;
            font-size: large;
            width: 24px;
            height: 24px;
            border: 0;
            border-radius: 8px;
            background-color: transparent;
            padding: 0;
          }
        }
      }
    }
  }

  &:focus-within {
    .optionWrapper {
      .option:not(.activeOption) {
        box-shadow: 0 0 8px colors.$accent;
        transition: 0.2s linear;
      }
    }
  }

  &:has(>.dragIcon) {
    cursor: move;
  }
}