@use '../common/fonts.scss';
@use '../common/colors.scss';

.body {
    position: relative;
    width: calc(100vw - 32px);
    height: calc(100vh - 90px);
    overflow: hidden;

    display: flex;
    flex-direction: column;

    .header {
        @include fonts.medium;
    }

    .filters {
        margin: 4px 8px 0 8px;
    }

    .container {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        flex-grow: 1;

        .mapContainer {
            position: relative;
            box-sizing: border-box;
            float: left;
            width: 50%;
            height: 100%;
            padding: 8px;
    
            .map {
                width: 100%;
                height: 100%;
                
                button {
                    border-radius: 0;
                }
            }
        }
    
        .listContainer {
            box-sizing: border-box;
            width: 50%;
            height: 100%;
            overflow-y: scroll;

            .noMatches {
                margin-top: 16px;
                width: 100%;
                text-align: center;
                @include fonts.medium;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .body {
        .container {
            .mapContainer {
                float: none;
                box-sizing: border-box;
                width: 100%;
                height: 50%;
                padding-bottom: 8px;
            }

            .listContainer {
                width: 100%;
                height: 50%;
                padding-left: 0;
            }
        }
    }
}

:export {
    borderColor: colors.$main-xdark; 
    fillColor: colors.$main;
    glyphColor: colors.$main-xdark;
    addedBorderColor: colors.$green-dark;
    addedFillColor: colors.$green;
    addedGlyphColor: colors.$green-dark;
}