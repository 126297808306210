@use '../common/colors.scss';

.scrollDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 400px;
    max-width: 400px;
    overflow: auto;
}

.scrollDiv input {
    margin: 8px;
    flex-shrink: 1;
}

.scrollDiv input[type=text] {
    min-width: 100px;
}

.scrollDiv>div {
    margin: 10px;
}

.scrollDiv img {
    padding: 8px;
    margin: 8px;
    height: 20px;
    cursor: pointer;
    border-radius: 5px;
    filter: invert(71%) sepia(27%) saturate(801%) hue-rotate(122deg) brightness(89%) contrast(84%);
}

.scrollDiv img:focus {
    box-shadow: 0 0 8px colors.$accent;
    outline: 0px;
}

.feedbackText {
    border: 1px solid colors.$gray;
    outline: none;
    padding: 16px;
    border-radius: 16px;
    width: 300px;
    max-height: 100px;
    overflow:hidden
}

.feedbackText:focus {
    box-shadow: 0 0 8px colors.$accent;
    border: 1px solid colors.$accent;
}

div.underline {
    padding: 0px;
    margin: 0px;
    height: 10px;
    width: 300px;
    border-bottom: 1px solid colors.$accent;
    animation: timer 1.5s linear;
}

@keyframes timer {
    0% { width: 300px }
    100% { width: 0px }
  }