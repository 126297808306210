@use '../common/colors.scss';
@use '../common/fonts.scss';
@use '../common/card.scss';
@use '../common/button.scss';

.filter {
    position: relative;

    .mainRow {
        display: flex;
        overflow-x: scroll;
        scrollbar-width: none;
        padding: 4px;

        .icon {
            display: block;
            mask: url('../../../public/icons/filter.svg');
            background-color: colors.$gray;
            min-height: 20px;
            min-width: 20px;
            margin: 0 8px 0 0;
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center;

            &.filterApplied {
                background-color: colors.$main-dark;
            }
        }

        .pill, .pill:enabled {
            @extend .button;
            @include fonts.medium;
            display: flex;
            align-items: center;
            padding: 8px 16px;
            border: 1px solid colors.$lightgray;
            border-radius: 32px;
            text-wrap: nowrap;
            cursor:pointer;
            background-color: transparent;

            &:not(:last-child) {
                margin-right: 8px;
            }

            &.selected {
                background-color: colors.$main-background;
                border: 1px solid colors.$main-dark;
                color: colors.$main-dark;

                .arrow {
                    background-color: colors.$main-dark;
                    transform: rotate(180deg);
                }
            }

            &.filtered {
                background-color: colors.$main-background;
                border: 1px solid colors.$main-dark;
                color: colors.$main-dark;

                .arrow {
                    background-color: colors.$main-dark;
                }
            }

            .arrow {
                display: inline-block;
                mask: url('../../../public/icons/arrow-icon.svg');
                background-color: colors.$gray;
                height: 12px;
                width: 12px;
                margin-left: 8px;
                mask-size: contain;
                mask-repeat: no-repeat;
                mask-position: center;
                transition: 0.2s linear;
            }
        }
    }

    .tags {
        position: absolute;

        &:not(:empty) {
            @extend .card;
            margin: 4px 0 0 0;
            z-index: 1;
            background-color: white;
            padding: 8px 16px 16px 16px;
            border-radius: 16px;
            box-shadow: 4px 4px 24px colors.$gray;
        }
    }
}