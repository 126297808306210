@use './colors.scss';

.button {
    border: 1px solid colors.$lightgray;
    outline: none;
    padding: 16px;
    font-size: medium;
    border-radius: 16px;
    height: 20px;
    box-sizing: content-box;
    vertical-align: top;
    cursor: pointer;
}
  
.button:enabled {
    border: 1px solid colors.$text-light;
    background-color: colors.$main;
    color: colors.$text-light;
    cursor: pointer;
}
  
.button:disabled {
    background-color: colors.$disabled;
    color: colors.$disabled-text;
}
  
.button:focus, .button:focus-within {
    box-shadow: 0 0 5px colors.$accent;
    border: 1px solid colors.$accent;
    transition: 0.2s linear;
}