@use '../common/colors.scss';
@use '../common/common.scss';

.notificationSettings {
	display: flex;
	align-items: center;
	justify-content: center;

    .checkboxWrapper {
        margin-right: 8px;
        height: 20px;

        .checkbox {
            appearance: none;
            background-color: colors.$gray;
            border-radius: 16px;
            border-style: none;
            padding: 0;
            height: 20px;
            margin: 0;
            position: relative;
            width: 32px;
            transition: all 0.2s ease-out;

            // &::before {
            //     bottom: -6px;
            //     content: "";
            //     left: -6px;
            //     position: absolute;
            //     right: -6px;
            //     top: -6px;
            // }

            &::after {
                background-color: colors.$text-light;
                border-radius: 50%;
                content: "";
                height: 14px;
                left: 4px;
                position: absolute;
                top: 3px;
                width: 14px;
                transition: all 0.2s ease-out;
            }

            &:hover {
                background-color: adjust-color($color: colors.$gray, $alpha: -0.2);
                transition-duration: 0s;
            }

            &:checked {
                background-color: colors.$main;

                &::after {
                    left: 14px;
                }

                &:hover {
                    background-color: adjust-color($color: colors.$main, $alpha: -0.2);
                }
            }
        }

        input[type=checkbox] {
            cursor: default;
        }
    }

    &:focus:not(.focus-visible) {
        outline: 0;
    }

    .finePrint {
        @extend .finePrint;
    }
}