@use '../common/fonts.scss'; 
@use '../common/colors.scss';

.body {
    margin-bottom: 16px;

    .header {
        @include fonts.title;
        color: colors.$main;
        width: 100%;
        text-align: center;
        margin-bottom: 8px;
    }
    
    .stepper {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        margin-bottom: 8px;
    
        .step {
            @include fonts.large;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            transition-duration: 0.5s;
    
            .number {
                width: 24px;
                height: 24px;
                padding: 8px;
                border: 1px solid colors.$dark;
                border-radius: 50%;
                text-align: center;
                line-height: 23px;
                transition: border-color, background-color;
                transition-duration: inherit;
            }
    
            .text {
                display: flex; 
                flex-direction: column; 
                align-items: center; 
                justify-content: center; 
                width: 24px;
                overflow: visible;
                transition: color;
                transition-duration: inherit;
            }
    
            &.active {
                .number {
                    border-color: colors.$accent;
                    background-color: colors.$accent;
                    color: colors.$text-light;
                }
    
                .text {
                    color: colors.$accent;
                }
            }
        }
    
        .line {
            width: 10%;
            height: 0px;
            border-top: 1px solid colors.$dark;
            margin: 20px;
        }
    }
}