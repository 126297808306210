@use '../common/colors.scss';
@use '../common/card.scss';
@use '../common/fonts.scss';

.vote {
  @extend .card;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  > div {
    margin: 0 0 8px 0;
    text-align: center;
  }

  .caption {
    @include fonts.medium;
  }

  .optionWrapper {
    display: flex;

    .option {
      box-shadow: 0 0 8px colors.$shadow;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 4px 0;
      font-size: large;
      border-radius: 16px;
      flex-grow: 1;
      color: colors.$dark;

      .voteIcon {
        content:url('../../../public/icons/thumb-up.svg');
        filter: saturate(0%) brightness(125%);
        margin: 0 8px;
        height: 32px;
      }

      .vetoIcon {
        content:url('../../../public/icons/thumb-down.svg');
        filter: saturate(0%) brightness(165%);
        margin: 0 8px;
        height: 32px;
      }

      &.selected {
        background-color: adjust-color($color: colors.$green, $lightness: 25%);
        box-shadow: 0 0 8px adjust-color($color: colors.$green, $lightness: 25%);
        // color: colors.$text-light;

        .voteIcon {
          filter: saturate(100%) brightness(100%);
          animation: wiggle 0.5s;
          animation-timing-function: cubic-bezier();
        }
      }

      &.vetoSelected {
        background-color: adjust-color($color: colors.$red, $lightness: 25%);
        box-shadow: 0 0 8px adjust-color($color: colors.$red, $lightness: 25%);
        // color: colors.$text-light;

        .vetoIcon {
          filter: saturate(100%) brightness(100%);
          animation: wiggle 0.5s;
          animation-timing-function: cubic-bezier();
        }
      }

      &.unselected {
        .voteIcon {
          visibility: hidden;
        }
      }

      &.vetoUnselected { 
        .vetoIcon {
          visibility: hidden;
        }
      }

      >span {
        margin: 15px;
      }

      .votes {
        margin: 0px 16px 0px auto;
        font-size: small;
        display: flex;
        flex-direction: column;

        > div {
          margin: 0px;
          text-align: right;
        }
      }
    }
  }
}

@keyframes wiggle {
  25% { transform: rotate(10deg) scale(1.2); }
  75% { transform: rotate(-10deg) scale(1.3); }
  100% { transform: rotate(0deg) scale(1); }
}
