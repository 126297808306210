@use '../common/colors.scss';
@use '../common/fonts.scss';
@use '../common/common.scss';

.group:not(:empty) {
  margin: 8px 0px;

  .invitation {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px 0;
    padding: 16px;
    border-radius: 24px;
    background-color: colors.$main-background;
  
    div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      span {
        display: block;
        align-self: center;
        line-height: 16px;
      }
  
      .code {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: 8px;
        font-family: fonts.$code;
        font-size: large;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 4px;
        text-wrap: nowrap;
        cursor: pointer;
  
        img {
          margin-left: 4px;
          width: 10px;
          mask: url('../../../public/icons/copy-icon.svg');
          background-color: colors.$main-dark;
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;
        }

        &:hover {
          img {
            background-color: colors.$main;
          }
        }
      }
    }
  
    a {
      font-size: medium;
    }
  }

  .contentHeader {
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    padding-top: 8px;
    color: colors.$dark;
  
    .numberTag {
      @extend .numberTag;
      background-color: adjust-color($color: colors.$main, $alpha: -0.75);
    }

    .numConnected {
      @extend .finePrint;
    }
  
    div {
      text-wrap: nowrap;
  
      &:first-child {
        // me
        font-weight: 500;
      }
    }
  }

  .participants {
    display: flex;
    flex-wrap: wrap;
  
    .participant {
      padding-left: 5px;
      text-wrap: nowrap;
      animation: prePulse, pulse;
      overflow: hidden;
      text-overflow: ellipsis;

      .others {
        font-style: italic;
      }
  
      &:nth-child(1) { animation-duration: 0.0s, 0.5s; animation-delay: 0s, 0.0s }
      &:nth-child(2) { animation-duration: 0.1s, 0.5s; animation-delay: 0s, 0.1s }
      &:nth-child(3) { animation-duration: 0.2s, 0.5s; animation-delay: 0s, 0.2s }
      &:nth-child(4) { animation-duration: 0.3s, 0.5s; animation-delay: 0s, 0.3s }
      &:nth-child(5) { animation-duration: 0.4s, 0.5s; animation-delay: 0s, 0.4s }
      &:nth-child(6) { animation-duration: 0.5s, 0.5s; animation-delay: 0s, 0.5s }
      &:nth-child(7) { animation-duration: 0.6s, 0.5s; animation-delay: 0s, 0.6s }
      &:nth-child(8) { animation-duration: 0.7s, 0.5s; animation-delay: 0s, 0.7s }
      &:nth-child(9) { animation-duration: 0.8s, 0.5s; animation-delay: 0s, 0.8s }
    }
  }
  
  .separator {
    font-weight: 900;
    color: colors.$gray;
  }
  
  .connected {
    color: colors.$main-dark;
    animation: connect 0.5s;
  }
}

@keyframes prePulse {
  0% { transform: scale(0); }
  100% { transform: scale(0) }
}

@keyframes pulse {
  0% { transform: scale(0); }
  75% { transform: scale(1.1) }
  100% { transform: scale(1) }
}

@keyframes connect {
  0% { color: colors.$gray  }
  30% { color: adjust-color($color: colors.$gray, $alpha: -1.0) }
  50% { color: adjust-color($color: colors.$green, $alpha: -1.0) }
  100% { color: colors.$green }
}