.methodDetails {
	font-size: small;
	font-style: italic;
	text-align: center;
}

@media screen and (max-width: 350px) {
	.selectionToggle input + label>img {
		display: none;
	}
}