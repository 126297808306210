@use '../common/fonts.scss';
@use '../home/MainCard.module.scss';

.mainCard {
  @extend .mainCard;

  .mainTitle {
    @extend .mainTitle;
    text-align: center;
  }

  .row {
      @extend .row;

      &.withinRow {
        @include fonts.medium;
        display: flex;
        align-items: center;

        >* {
          margin: 0 4px;
        }
  
        .withinField {
          padding: 8px;
          font-size: medium;
          width: 24px;
          text-align: center;
          appearance: textfield;
          -moz-appearance: textfield;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
          }
        }
      }

      .locationField {
        height: 20px;
        flex-grow: 1; // expand with the window, up to max-width
        max-width: 450px;
        margin: 0px 8px 8px 0px;
      }
  }
}