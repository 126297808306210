.wrapper {
    padding: 4px;
    margin: -4px;
    border-radius: 4px;

    .text {
        text-decoration: underline dashed 1px;
        text-underline-offset: 4px;
        cursor: pointer;
    }
}

