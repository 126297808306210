@use '../common/card.scss';
@use '../common/fonts.scss';

.vote {
  @extend .card;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  > div {
    margin: 0 0 8px 0;
    text-align: center;
  }
  
  .caption {
    @include fonts.medium;
  }
}
