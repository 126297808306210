@use '../common/fonts.scss';
@use '../common/colors.scss';
@use '../common/common.scss';
@use '../common/button.scss';

.place {

    display: flex;
    border: 1px solid colors.$main;
    border-radius: 16px;
    padding: 16px;
    margin: 8px;
    z-index: 1;

    &.active {
        box-shadow: 0 0 8px colors.$accent;
        border-color: colors.$accent;
    }

    &.filtered {
        height: 0;
        padding: 0;
        margin: 0;
        border: 0;
        animation: hide 0.5s linear;
        filter: opacity(0);
    }

    >div {
        position: relative;
        flex-grow: 1;
        overflow: hidden;
    }

    .name {
        @include fonts.medium;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        cursor:default;
        margin-top: 2px;

        >span {
            @include fonts.small;
            vertical-align: top;
            cursor:default;

            &:not(:last-child):not(:empty) {
                margin-right: 4px;
            }
        }

        .stars {
            position: relative;
            width: calc(12px * 5);
            height: 16px;
            margin: 0;

            .star {
                display: inline-block;
                mask: url('../../../public/icons/star.svg');
                mask-size:cover;
                width: 12px;
                height: 12px;
            }

            .filled {
                position: absolute;
                text-wrap: nowrap;
                --rating: 3.5;
                max-width: calc(12px * var(--rating));
                overflow: hidden;

                .star {
                    background-color: colors.$main-dark;
                }
            }

            .empty {
                position: absolute;
                text-wrap: nowrap;
    
                .star {
                    background-color: colors.$main-background;
                }
            }
        }
    }

    .address {
        @include fonts.small;
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .button {
        @extend .smallButton;
        margin-left: 12px;

        &:disabled {
            color: colors.$text-light;
            background-color: colors.$green-light;
        }
    }
}