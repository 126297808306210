@use '../common/colors.scss';
@use '../common/fonts.scss';
@use '../common/card.scss';

.results {
  display: flex;
  flex-direction: column;
  width: 100%;

  .card {
    @extend .card;
    width: calc(100% - 24px);
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 776px;
    box-sizing: border-box;

    .contentBox {
      display: flex;
      flex-direction: column;
      margin: 0 auto 8px auto;
      width: 100%;

      .contentHeader {
        @include fonts.large;
        text-align: center;
        margin: 4px 0;
      }

      .subHeader {
        @include fonts.medium;
        margin: 4px 0;
      }

      .winner {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 8px 0;
        padding: 8px;
        border-radius: 16px;
        background-color: colors.$main-background;
        overflow: hidden;
        // box-shadow: 0px 0px 5px #f78733;

        &.glow {
          animation: noGlow 2s, glowPulse 0.75s ease-in-out;
          animation-delay: 0s, 2s;
        }

        .option {
          margin: 4px 16px;
          display: flex;
          flex-direction: column;

          > span {
            @include fonts.large;
            margin-bottom: 4px;
            color: colors.$main-dark;
          }
        }
      }

      .option {
        @include fonts.medium;
        margin: 4px 0;
        display: flex;
        align-items: center;
      }
    }
  }
}

@keyframes noGlow {
  0% { box-shadow: 0px 0px 2px colors.$accent; }
  100% { box-shadow: 0px 0px 2px colors.$accent; }
}

@keyframes glowPulse {
  0% { box-shadow: 0px 0px 2px colors.$accent; }
  50% { box-shadow: 0px 0px 10px colors.$accent; }
}
