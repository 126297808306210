@use '../common/colors.scss';
@use '../common/fonts.scss';
@use '../common/card.scss';
@use '../common/button.scss';

.feedback {
    @extend .card;

    .header {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin: 0;

        @include fonts.large;

        a {
            @include fonts.medium;
        }
    }

    > div {
        display: flex;
        justify-content: center;
        margin: 8px;
    }

    .star:enabled {
        @extend .button;
        background-color: transparent;
        border: 0;
        margin: 0px;
        padding: 8px;
        height: 36px;
        width: 36px;
        cursor: pointer;

        img {
            mask: url('../../../public/icons/star.svg');
            background-color: colors.$lightgray;
            height: 100%;
            width: 100%;
            margin: 0;
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center;
        }

        &.selected {
            img {
                background-color: colors.$main;
                animation: wiggle 0.5s;
            }
        }
    }

    .feedbackText {
        border: 1px solid colors.$lightgray;
        outline: none;
        padding: 16px;
        border-radius: 16px;
        width: 100%;
        max-width: 500px;
        max-height: 100px;
        overflow:hidden;

        &:focus {
            box-shadow: 0 0 8px colors.$accent;
            border: 1px solid colors.$accent;
            transition: 0.2s linear;
        }
    }
}

@keyframes wiggle {
    25% { transform: rotate(10deg) scale(1.2); }
    75% { transform: rotate(-10deg) scale(1.3); }
    100% { transform: rotate(0deg) scale(1); }
  }