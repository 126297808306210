@use '../common/colors.scss';
@use '../common/fonts.scss';

.howItWorks {
  padding: 16px;
  background-color: colors.$main-background;
  color: colors.$dark;

  .title {
    @include fonts.header;
    color: colors.$main-dark;
  }

  .step {
    margin: 8px 0;

    .stepLeft {
      display: flex;
      flex-direction: column;
      float: left;
      position: relative;
      width: 64px;

      .number {
        @include fonts.large;
        font-size: 18px;  // need pixel size to ensure it's centered vertically
        margin-right: 16px;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        padding: 8px;
    
        border: 2px solid colors.$main-dark;
        color: colors.$text-light;
        background-color: colors.$main-dark;
        text-align: center;
      }

      // .arrow {
      //   background: url("../../../public/images/swirl-arrow-right.svg") no-repeat;
      //   position: absolute;
      //   top: 80px;
      //   left: -20px;
      //   width: calc(64px * 2.1);
      //   height: calc(24px * 2.1);
      //   background-size: cover;
      //   transform: rotate(100deg);
      //   filter: invert(52%) sepia(97%) saturate(522%) hue-rotate(338deg) brightness(103%) contrast(94%);
      //   z-index: 0;
      // }
    }

    .header {
      @include fonts.header;
      color: colors.$main-dark;

      margin-left: 64px;
      padding: 4px 0 0 0;
      display: block;
    }

    .detail {
      @include fonts.medium;

      display: block;
      margin-left: 64px;
      margin-bottom: 8px;

      // div {
      //   display: flex;
      //   min-width: 56px;

      //   img {
      //       // position: absolute;
      //       float: left;
      //       right: 0px;
      //       height: 40px;
      //       filter: invert(52%) sepia(97%) saturate(522%) hue-rotate(338deg) brightness(103%) contrast(94%);
      //       animation: wiggle 4s infinite;
      
      //       &.wiggle1 {
      //         animation-delay: 0s;
      //       }
      
      //       &.wiggle2 {
      //         animation-delay: 1s;
      //       }
      
      //       &.wiggle3 {
      //         animation-delay: 2s;
      //       }
      //   }
      // }

      span {
        z-index: 1;
      }

      ul {
        margin: 0;
      }

      p {
        margin: 0;
      }
    }

    .line {
      border-bottom: 1px solid colors.$main-dark;
      margin: 16px auto;
    }
  }
}

.emphasis {
  font-style: italic;
  font-weight: 700;
}

// @keyframes wiggle {
//   5% { transform: rotate(5deg); }
//   10% { transform: rotate(-5deg); }
//   15% { transform: rotate(5deg); }
//   20% { transform: rotate(0deg); }
//   100% { transform: rotate(0deg); }
// }

// @keyframes still {
//   0% { }
//   100% {}
// }