.rankTotals {
  display: flex;
  margin-left: auto;
  --firstMargin: 0px;
  --secondMargin: 0px;
  --thirdMargin: 0px;

  &.centered {
    margin-left: 0;
  }

  >span {
    text-wrap: nowrap;
  }

  .firstIcon {
    content:url('../../../public/icons/gold-medal.svg');
    margin: 0px 8px;
    height: 20px;
  }
  
  .firstIcon + .firstIcon {
    position: relative;
    margin-left: var(--firstMargin);
  }
  
  .secondIcon {
    content:url('../../../public/icons/silver-medal.svg');
    margin: 0px 8px;;
    height: 20px;
  }
  
  .secondIcon + .secondIcon {
    position: relative;
    margin-left: var(--secondMargin);
  }
  
  .thirdIcon {
    content:url('../../../public/icons/bronze-medal.svg');
    margin: 0px 8px;
    height: 20px;
  }
  
  .thirdIcon + .thirdIcon {
    position: relative;
    margin-left: var(--thirdMargin);
    border-radius: 16px;
  }
}