body {
  margin: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.pac-container {
  border-radius: 16px;
  padding: 16px;
}

.pac-item {
  border: 0;
  border-radius: 8px;
}

.pac-item-selected {
  background-color: #CEE6FB;
}

.pac-item-selected .pac-icon-marker {
  background-position: -1px -161px;
  filter: brightness(0) invert(40%) sepia(82%) saturate(1836%) hue-rotate(189deg) brightness(90%) contrast(100%);
}

.pac-matched {
  color: #1382E4;
}

