@use '../common/colors.scss';
@use '../common/card.scss';

.nominate {
  @extend .card;
  min-width: 300px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .contentBox {
    margin: 0 auto 8px auto;
    width: 100%;
  
    .contentHeader {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      font-weight: 500;
      text-wrap: nowrap;
      color: colors.$dark;

      .aiPrompt {
        margin-left: auto;
        display: flex;
        font-size: small;
        font-weight: normal;

        img {
          width: 20px;
        }
      }
    }

    .addSuggestions {
      margin-top: 8px;
      white-space: nowrap;
      display: flex;

      .button {
        margin-left: 8px;
      }

      input[type="text"] {
        flex-grow: 1;
        min-width: 100px;
      }
    }

    .suggestionList {
      margin: 8px 8px 0 0;
      padding-left: 0px;
      list-style: inside;
      line-height: 24px;

      button:enabled {
        background: transparent;
        border: 0;
        cursor: pointer;
        color: colors.$main-dark;
        padding: 4px;
        border-radius: 8px;
        line-height: 16px;
        margin-left: 4px;
      }

      // a {
      //   color: inherit;
      //   text-decoration: underline dashed 1px #666666;
      //   text-underline-offset: 4px;
      // }
    }

    .noSuggestions {
      padding: 8px 8px 8px 0px;
      font-style: italic;
      color: colors.$dark;
    }
  }
}


