@use '../common/colors.scss';
@use '../common/fonts.scss';
@use '../common/card.scss';
@use '../common/button.scss';

.mainCard {
  @extend .card;
  max-width: 500px;

  .mainTitle {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
      @include fonts.title;
      line-height: 32px;
      span {
        text-wrap: nowrap;
  
        span {
          color: colors.$main;
          text-wrap: nowrap;
      
          .super {
            // layout
            display: inline-block;
  
            // text
            font-size: small;
            opacity: 35%;
            vertical-align: 14px;
  
            // boundaries
            margin-top: -14px;
            margin-right: -6px;
          }
      
          .sub {
            // layout
            display: inline-block;
  
            // text
            font-size: small;
            opacity: 35%;
            vertical-align: -6px;
  
            // boundaries
            margin-left: -4px;
            margin-bottom: -6px;
          }
        }
      }
    }
  }

  .row {
    // layout
    display: flex;
    justify-content: center;
  
    // text
    @include fonts.large;
  
    // boundaries
    margin: 8px 0 0 0;

    .questionField {
      height: 20px;
      flex-grow: 1; // expand with the window, up to max-width
      max-width: 450px;
      margin: 0px 8px 8px 0px;
    }

    .codeField {
      // layout
      width: 110px;
    
      // text
      text-align: center;
      @include fonts.code;
    
      // boundaries
      margin: 0 8px 8px 0;
    }

    .button {
      @extend .button;
    }
  }
}