@import '../common/common.scss';

.scrollDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 400px;
    max-width: 400px;
    overflow: auto;

    .message {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .city {
            font-size: small;
        }

        img {
            padding: 20px;
            width: 50px;
        }
    }

    .suggestions {
        display: flex;
        flex-direction: column;

        >div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            height: 30px;

            input {
                margin-left: auto;
            }

            >img {
                position: fixed;
                float: right;
                width: 0px;
            }

            >div {
                padding-right: 10px;
            }
            
            &.hidden {
                height: 0;
                padding: 0 10px;
                overflow: hidden;
                animation: hide 1s ease-in;
                filter: opacity(0);

                >img {
                    width: 30px;
                    right: 0px;
                    animation: slide 1s ease-out;
                }     
            }
        }
    }
}

@keyframes hide {
    0% { 
        height: 30px;
        padding: 10px; 
        filter: opacity(1);
    }
    100% { 
        height: 0;
        padding: 0 10px;
        filter: opacity(0);
    }
}

@keyframes slide {
    0% {
        right: 200px;
    }
    100% {
        right: 0px;
    }
}

