@use '../common/colors.scss';
@use '../common/fonts.scss';

.collaborate {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .body {
    margin: 64px 0 0 0; // space down from title

    .error {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include fonts.medium;
      color: colors.$main;
    }
    
    .error > div {
      margin: 8px;
    }

    .loading {
      @include fonts.header;
      text-align: center;
    }
  }
}