$main: #3E99EE; // 3:1 contrast against white, sufficient for large text
$accent: #F2A35E;   // avoid using for text due to insufficient contrast

$dark: #667273; // adjust-color($color: $main, $lightness: -40%);
$dark-header: #959595;  // 3:1 contrast against white
// adjust-color($color: $dark, $alpha: -0.2);
$disabled: #E0E0E0;
$disabled-text: #707070;
$main-background: #CEE6FB;   // adjust-color($color: $main, $alpha: -0.75);
$shadow: #00000025;

$main-dark: #1382E4;    // 3:1 contrast against $main-background, sufficient for large text
$main-dark-filter: invert(28%) sepia(99%) saturate(339%) hue-rotate(122deg) brightness(92%) contrast(95%);

$text-light: #FFFFFF;

$gray: #909090;
$lightgray: #E0E0E0;
$green: #33a867;
$red: #f44336;

$main-xdark: adjust-color($main, $lightness: -25);
$green-dark: adjust-color($green, $lightness: -15);
$green-light: adjust-color($green, $lightness: 25);