@use '../common/card.scss';
@use '../common/common.scss';
@use '../common/colors.scss';
@use '../common/button.scss';

.card {
  @extend .card;
  width: 100%;

  .nextStep {
    text-align: center;

    div {
      font-size: small;
      text-align: center;
      font-style: italic;
      margin: 8px;
    }

    .feedbackLink {
      margin: 8px;
      font-weight: 500;
    }

    .buttonRow {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content:center;

      .button {
        @extend .button;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 8px;
        height: auto;

        span {
          margin: 4px 8px;
          text-wrap: nowrap;
        }
        
        img {
          height: 24px;
          width: 24px;
          background-color: #000000;
          border: 0;
          margin: 0;

          &.repeat {
            mask: url(../../../public/icons/repeat.svg);
            mask-size: 20px;
            mask-repeat: no-repeat;
            mask-position: center;
          }

          &.new {
            mask: url(../../../public/icons/plus.svg);
            mask-size: 20px;
            mask-repeat: no-repeat;
            mask-position: center;
          }
        }
      }
    }
  }

  .finePrint {
    @extend .finePrint;
  }
}