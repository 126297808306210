@use './colors.scss';

.title {
    position: fixed;
    top: 0;
    padding: 16px 0 8px 0;
    width: 100%;
    z-index: 999;
    text-align: center;
    background-color: transparent;
    transition: 0.2s;

    img {
      width: 240px;
      cursor: pointer;
    }
  
    // .beta {
    //   position: absolute;
    //   top: 0px;
    //   margin: 2px 0 0 10px;
    //   padding: 5px;
    //   border: #4abdac solid 1px;
    //   border-radius: 10px;
    //   box-shadow: 0px 0px 5px $main;
    //   text-align: center;
    //   color:#666666;
    //   font-size: x-small;
    //   font-weight: 500;
    //   text-transform: uppercase;
    //   cursor: pointer;
    // }
  }

  html:not([data-scroll='0']) {
    .title {
      background-color: colors.$text-light;
      box-shadow: 4px 4px 16px colors.$shadow;
    }
  }