@use '../common/colors.scss';


.layout {
    --leftHeight: 200px;
    --rightHeight: 200px;

    width: 100%;
    position: relative;
    transition-duration: 0.5s;  // defining here to make it consist for all children

    // using absolute positioned divs so that we can animate
    // responsive positions

    .left {
        position: absolute;
        width: 50%;
        max-width: 800px;
        right: 50%;
        display: flex;
        transition: width, right;
        transition-duration: inherit;
    }

    .right {
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        max-width: 800px;
        transition: width, left, top;
        transition-duration: inherit;

        &:first-child {
            left: 0;
            width: 100%;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .rightTop {
            display: flex;
            width: 100%;
            max-width: 800px;
        }

        .bottom {
            display: flex;
            width: 100%;
            max-width: 800px;
            margin: 0 auto;
        }
    }
}

.tooltip {
    max-width: 100%;
    background-color: colors.$dark;
    color: colors.$text-light;
    border-radius: 16px;
    box-shadow:4px 4px 24px colors.$shadow;
}

@media screen and (max-width: 800px) {
    .layout {
        .left {
            right: 0px;
            width: 100%;
        }

        .right {
            left: 0;
            top: var(--leftHeight);
            width: 100%;

            &:first-child {
                top: 0;
            }

            .bottom {
                right: 0px;
                top: calc(var(--leftHeight) + var(--rightHeight));
            }
        }
    }
}