@use './colors.scss';
@use '../common/common.scss';
@use '../common/button.scss';

.toggleButtonRow {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 8px;

  .button {
    @extend .button;
    height: auto;
    margin-left: 1px;
    margin-right: 1px;
    border-radius: 4px;
    padding: 8px;
    flex-grow: 2;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    
    span {
      display: inline-block;
      margin: 4px 8px;
    }

    &:first-child {
      border-top-left-radius: 16px;
      border-end-start-radius: 16px;
    }

    &:last-child {
      border-end-end-radius: 16px;
      border-start-end-radius: 16px;  
    }

    &.selected {
      background-color: colors.$main;
      color: black;
      transition: 0.2s linear;
      
      img {
        animation: wiggle 0.5s ease-in-out;
      }
    }

    &.unselected {
      background-color: colors.$main-background;
      color: colors.$main-dark;
      transition: 0.2s linear;

      img {
        background-color: colors.$main-dark;
      }
    }

    img {
      height: 16px;
      width: 16px;
      margin: 4px 8px;
      background-color: black;
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
    }
  }

  &.small {
    margin: 0;

    button {
      padding: 4px;

      span {
        font-size: small;
        margin: 4px;
      }

      &:first-child {
        border-top-left-radius: 8px;
        border-end-start-radius: 8px;
      }
  
      &:last-child {
        border-end-end-radius: 8px;
        border-start-end-radius: 8px;  
      }
    }
  }
}

@keyframes wiggle {
  25% { transform: rotate(10deg) scale(1.2); }
  75% { transform: rotate(-10deg) scale(1.3); }
  100% { transform: rotate(0deg) scale(1); }
}