@use '../common/common.scss';
@use '../common/fonts.scss';

.tieBreaker {
    display: flex;
    align-items: center;
    margin: 0 auto;
    @include fonts.small;
    font-size: small;

    > * {
      margin: 0 8px;
      text-wrap: nowrap;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
}

.smallButton {
  @extend .smallButton;
}
