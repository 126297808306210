@use '../common/colors.scss';

.voteBar {
    position: relative;
    width: 100%;
    height: 8px;
    background-color: colors.$lightgray;
    border-radius: 4px;
    margin-bottom: 8px;

    .votes {
        --value: 0%;

        position: absolute;
        left: 0;
        width: var(--value);
        height: 100%;
        border-radius: 4px;
        background-color: colors.$green;
        transition: width 1s;
    }

    .vetoes {
        --value: 0%;

        position: absolute;
        right: 0;
        width: var(--value);
        height: 100%;
        border-radius: 4px;
        background-color: colors.$red;
        transition: width 1s;
    }
}