@use '../common/colors.scss';
@use '../common/fonts.scss';

.popupContainer {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: adjust-color($color: colors.$gray, $alpha: -0.5);
    display: flex;
    align-items: center;
    z-index: 999;

    .popup {
        /* min-width: 400px; */
        max-width: calc(100vw - 32px);
        background-color: colors.$text-light;
        border: 0px;
        border-radius: 16px;
        box-shadow: 0 4px 16px 0 colors.$gray;
        margin: 8px auto;
        overflow: hidden;

        .caption {
            @include fonts.large;
            background-color: colors.$main;
            color: colors.$text-light;
            padding: 16px;
            display: flex;
            align-items: flex-start;

            .icon {
                height: 16px;
                width: 16px;
                padding-right: 10px;
            }

            .captionText {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-right: 16px;
            }

            .closeX {
                margin-left: auto;
                cursor: pointer;
            }
        }
    }

    .popupBody {
        margin: 0;
        animation-name: expandBody;
        animation-duration: 0.5s;
        animation-timing-function: linear;
    }
}

@keyframes expandBody {
    from {
        max-height: 0px;
    }

    to {
        max-height: 400px;
    }
}