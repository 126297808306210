@use '../fonts.scss';
@use '../colors.scss';

.pill {
    display: inline-block;
    padding: 8px;
    margin: 8px 8px 0 0;
    border-radius: 8px;
    @include fonts.small;
    color: colors.$text-light;
    background-color: colors.$main; 
    border: 1px solid transparent;

    &.clickable {
        cursor: pointer;
    }

    &:has(.clickHint:focus) {
        border: 1px solid colors.$accent;
        box-shadow: 0 0 8px colors.$accent;
        transition: 0.2s linear;
    }

    .content {
        text-wrap: nowrap;
    }

    .clickHint {
        margin: 0;
        padding: 0;
        border: 0;
        margin-left: 8px;
        color: colors.$text-light;

        &:focus {
            outline: none;
            border: 0;
            box-shadow: 0 0 0;
            color: colors.$accent;
            transition: 0.2s linear;
        }
    }
}