.scrollDiv {
    display: flex;
    align-items: center;
    max-height: 400px;
    max-width: 400px;
    overflow: auto;
}

.scrollDiv input {
    margin: 5px;
    flex-shrink: 1;
}

.scrollDiv input[type=text] {
    min-width: 100px;
}