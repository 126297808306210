@use '../common/colors.scss';
@use '../common/fonts.scss';
@use '../common/card.scss';

.contentBox {
    display: flex;
    flex-direction: column;
    margin: 0 auto 8px auto;
    width: 100%;

    .header {
        @include fonts.medium;
        margin: 4px 0;

        .rounds {
            float: right;
            @include fonts.small;

            .selected {
                font-weight: 600;
            }
        }
    }

    .option {
        @include fonts.medium;
        margin: 4px 0;
        display: flex;
        align-items: center;
    }
}
