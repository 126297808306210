@use './colors.scss';

.textWrapper {
    position: relative;
    padding-right: 8px;
    transition: 0.2s linear;
    height: 100%;

    &:has(.searchText:empty) {
        max-width: 0;
        padding-right: 0;
    }

    &:focus-within {
        max-width: none;
        min-width: 80px;
    }

    .searchText {
        text-align: left;
        outline: none;
        white-space: nowrap;
        overflow-y: hidden;
        height: 100%;
    
        &::selection {
            color: colors.$text-light;
            background: colors.$main;
        }
    
        br {
            display: none;
        }
    
        * {
            display:inline;
            white-space:nowrap;
        }
    }
}

.search {
    display: block;
    mask: url('../../../public/icons/search.svg');
    background-color: colors.$gray;
    min-height: 24px;
    min-width: 24px;
    margin: 0;
    mask-size: 80%;
    mask-repeat: no-repeat;
    mask-position: center;
    cursor: pointer;

    &:focus {
        background-color: colors.$accent;
    }
}