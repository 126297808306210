@use './Nominate.module.scss';
@use '../common/fonts.scss';
@use '../common/colors.scss';
@use '../common/button.scss';

.noSuggestions {
    @include fonts.small;
}

.centered {
    padding: 16px 0 0 0;
    text-align: center;

    a {
        font-weight: 600;
    }

    .mapButton:enabled {
        outline: none;
        display: flex;
        align-items: center;
        position: relative;
        color: colors.$main;
        font-size: large;
        font-weight: 800;
        overflow: hidden;
        z-index: 1;
        background-color: white;
        border: 4px colors.$main solid;
        border-radius: 16px;
        padding: 12px;
        line-height: 16px;
        margin-left: auto;
        margin-right: auto;

        &:focus {
            border: 4px colors.$accent solid;
            box-shadow: 0 0 5px colors.$accent;
            transition: 0.2s linear;
        }

        &:after {
            content: ' ';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0.7;
            background-image: url('../../../public/images/map.png');
            background-repeat: no-repeat;
            background-position: -24px -24px;
            background-size: 130%;
            z-index: -1;
            background-clip: border-box;
        }

        .markerImage {
            display: inline-block;
            mask-image: url('../../../public/icons/map-marker.svg');
            background-color: colors.$accent;
            height: 24px;
            width: 24px;
            margin-left: 8px;
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center;
        }
    }
}