@use '../common/colors.scss';
@use '../common/common.scss';

.settings {
  .contentBox {
    margin-top: 8px;
    width: 100%;

    .contentHeader {
      font-weight: 500;
      color: colors.$dark;
    }

    .fieldBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .fieldWrapper {
        margin: 8px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        max-width: 200px;

        &.fullRow {
          width: 100%;
          max-width: 100%;;
        }

        div {
          width: 100%;
        }

        > span {
          margin: 0 8px;
          display: flex;
          text-wrap: nowrap;

          > img {
            margin-right: 5px;
          }

          .icon {
            height: 25px;
          }
        }

        > input[type="text"] {
          /* margin-left: auto; */
          padding: 8px;
          font-size: medium;
          width: 24px;
          text-align: center;
        }

        // > input[type="checkbox"] {
        //   /* margin-left: auto; */
        //   width: 30px;
        //   height: 30px;
        //   padding: 10px;
        //   margin: 0px;
        //   font-size: medium;
        //   width: 20px;
        //   text-align: center;
        // }
      }
    }
  }

  .finePrint {
    @extend .finePrint;
    text-align: center;

    > span {
      text-wrap: nowrap;

      .icon {
        height: 14px;
      }
    }
  }
}