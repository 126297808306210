@use '../common/colors.scss';

$code: "DM Mono", monospace;

@mixin title {
    font-family: "Kanit", sans-serif;
    font-size: xx-large;
    font-weight: bolder;
    color: colors.$dark-header;
}

@mixin header {
    font-family: "Kanit", sans-serif;
    font-size: x-large;
    font-weight: 700;
    color: colors.$dark-header;
}

@mixin large {
    font-family: "Kanit", sans-serif;
    font-size: 1.4rem;
    font-weight: 500;
    color: colors.$dark-header;
}

@mixin medium {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: medium;
    font-weight: 500;
    color: colors.$dark;  
}

@mixin small {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: small;
    font-weight: 400;
    color: colors.$dark;  
}

@mixin code {
    font-family: "DM Mono", monospace;
    font-size: medium;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 8px;
    color: colors.$dark;
}