@use '../common/colors.scss';
@use '../common/fonts.scss';

.spinner {
    position: relative;
    left: 620px;
    min-width: 2000px;
    display: flex;
    position: relative;
    animation: slide 2s cubic-bezier(0.25, 0, 0, 1.0);
    
    > .option {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 120px;
        margin: 4px 16px;

        &.notWinner {
            opacity: 0%;
            animation: stayVisible 2s, fadeOut 0.75s;
            animation-delay: 0s, 2s;
        }

        > span {
            margin-bottom: 4px;
            width: 100%;
            text-align: center;
            @include fonts.large;
            color: colors.$main-dark;
        }
    }
}

@keyframes slide {
    0% { left: -790px; }
    100% { left: 620px; }
}

@keyframes stayVisible {
    0% { opacity: 100% }
    100% {opacity: 100% }
}

@keyframes fadeOut {
    0% { opacity: 100% }
    100% {opacity: 0% }
}
