.body {
    --leftHeight: 328px;    // defines a fixed height for the left component

    // layout
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 0 auto 0 auto;

    .top {
        margin: 0;
        padding: 0;
        width: 50%;
        height: var(--leftHeight);
        display: flex;
        justify-content: flex-end;
        z-index: 2;

        &.center {
            // right component isn't showing, so center the left component
            width: 100%;
            justify-content: center;
        }
    }

    .bottom {
        width: 100%;
      
        .right {
          float: right;
          width: 50%;
          z-index: 1;
      
          // position the right component in line with left component
          position: relative;
          top: calc(0px - var(--leftHeight));
          margin-bottom: calc(-24px - var(--leftHeight));
        }
    }
}

@media screen and (max-width: 900px) {
    .body {
        justify-content: center;

        .top {
            width: 100%;
            justify-content: center;
        }

        .bottom {
            .right {
                float: none;
                width: 100%;
                max-width: 100%;
                display: flex;
                justify-content: center;
            
                position: static;
                top: 0;
                margin-bottom: 0;
            }
        }
    }
}